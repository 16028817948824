import * as Sentry from "@sentry/browser";

Sentry.onLoad(function() {
  Sentry.init({
    release: "oqoro@" + process.env.RENDER_GIT_COMMIT,
    dsn: "https://b768400c615d44fe8a10e9d4572b0d5f@o386364.ingest.us.sentry.io/4504434083627008",
    environment: 'production',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracePropagationTargets: ["oqoro.com", "regie.oqoro.com", "app.oqoro.com", "www.oqoro.com"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
});
window.Sentry = Sentry
